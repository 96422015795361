import { useApolloClient } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, FormGroup, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useActions from '../../../app/hooks';
import { selectUser, UserState } from '../../../global/state/user-slice';
import { Robot } from '../../../types/base';
import BootstrapDialogTitle from './BootstrapDialogTitle';

interface Props {
  children?: React.ReactNode;
  onClose: (robot: Robot | null) => void; //The updated robot is returned
  isUpdate: boolean; //Otherwise it's a create dialog.
  updatingRobot?: Robot | undefined; //Optional - needed for update.
}

/**
 * TODO: Complete
 * I need synchronous robot updates for this to work.
 *
 * @export
 * @param {Props} props
 * @return {*}
 */
export default function AddUpdateRobotDialog(props: Props) {
  const { children, onClose, ...other } = props;
  const globalState = useApolloClient();
  const actions = useActions();

  const userState: UserState | undefined = useSelector(selectUser);

  const [label, setLabel] = useState<string>();
  const [labelError, setLabelError] = useState<string>('Robot name is required');

  const [metadata, setMetadata] = useState<string>('{\r\n   "RobotParam": "Example Parameter"\r\n}');

  function handleCreateOrUpdate() {
    if (!userState?.user) return;

    if (props.isUpdate) {
      const updatedRobot = { ...props.updatingRobot!, metadata: metadata };
      actions.updateRobot({
        apolloClient: globalState,
        robot: updatedRobot,
      });
    } else
      actions.addRobot({
        apolloClient: globalState,
        user: userState!.user,
        robotRequest: {
          label: label,
          metadata: metadata,
        },
      });
    props.onClose(null);
  }

  function onLabelChanged(e) {
    const newVal = e.target.value;
    setLabel(newVal);
    if (newVal.trim() == '') setLabelError("Robot name can't be empty");
    else if (newVal.length > 64) setLabelError('Robot name must be shorter than 64 characters');
    else {
      setLabelError('');
    }
  }

  return (
    <Dialog open={true} onClose={() => props.onClose(null)} fullWidth>
      <BootstrapDialogTitle id="dialog" onClose={() => props.onClose(null)}>
        {props.isUpdate ? 'Update' : 'Add a New'} Robot
      </BootstrapDialogTitle>
      <DialogContent>
        <FormGroup
          row={true}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
            marginBottom: '10px',
            padding: 1,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Robot101"
            label="Robot Name"
            value={label}
            onChange={onLabelChanged}
            error={labelError !== ''}
            helperText={labelError}
            sx={{ margin: 1 }}
            fullWidth
          />
          <TextField
            variant="outlined"
            label="Metadata"
            sx={{ margin: 1 }}
            maxRows={6}
            multiline={true}
            value={metadata}
            onChange={(e) => setMetadata(e.target.value)}
            placeholder="Optional metadata for the robot"
            fullWidth
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.onClose(null)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleCreateOrUpdate()}
          disabled={labelError !== ''}
        >
          {props.isUpdate ? 'Update' : 'Create'} robot
        </Button>
      </DialogActions>
    </Dialog>
  );
}
