import { useApolloClient } from '@apollo/client';
import { Alert } from '@mui/lab';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useActions, { useAppDispatch } from '../../../app/hooks';
import { MapperState, selectMaps, setSelectedMap } from '../../../global/state/maps-slice';
import { UserState, selectUser } from '../../../global/state/user-slice';
import { Spinner } from '../../common/spinner';
import { DarkNegativeCreateIcon } from '../../icons/icons';
import UseExampleDatasetDialog from '../UseExampleDatasetDialog';
import CreateMapDialog from './CreateMapDialog';
import MapDetailsDialog from './MapDetailsDialog';
import MapSummaryItem from './MapSummaryItem';

export default function MapperSummaryCard() {
  const globalState = useApolloClient();
  const dispatch = useAppDispatch();
  const actions = useActions();

  const mapsState: MapperState | undefined = useSelector(selectMaps);
  const userState: UserState | undefined = useSelector(selectUser);

  const [createDialogOpen, setCreateDialogOpen] = useState<Boolean>(false);
  const [exampleDatasetDialogOpen, setExampleDatasetDialogOpen] = useState<Boolean>(false);

  // If the ID token changes, get the maps.
  React.useEffect(() => {
    // On mounting get the latest user
    if (userState.user != undefined)
      actions.getMaps({ apolloClient: globalState, user: userState.user });  
  }, [userState.user]);

  var content = null;
  if (mapsState.status == 'error') {
    content = (
      <Alert severity='error'
        action={
          <Button
            onClick={() =>
              userState.user != undefined ? actions.getMaps({ apolloClient: globalState, user: userState.user }) : null
            }
          >
            Reload
          </Button>
        }
      >{mapsState.error}</Alert>
    );
  }
  if (mapsState.status == 'loading') {
    content = <Spinner />;
  }
  if (mapsState.status == 'idle') {
    content = (
      <Grid container spacing={1}>
        {mapsState.maps.map((m) => (
          <MapSummaryItem map={m} />
        ))}
      </Grid>
    );
  }

  return (
    <Card>
      <CardHeader
        title="My Maps"
        subheader="NavAbility Mapper lets you create and update maps of environments for your robots."
      ></CardHeader>
      <CardActions>
          <Button
            variant="contained"
            color="secondary"
            component="span"
            size="large"
            startIcon={<DarkNegativeCreateIcon fontSize="large" />}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create a map
          </Button>
        <Tooltip title="This is still under construction">
          <Button
            variant="contained"
            color="primary"
            component="span"
            size="large"
            startIcon={<DarkNegativeCreateIcon fontSize="large" />}
            // onClick={() => setExampleDatasetDialogOpen(true)}
          >
            Try an example dataset...
          </Button>
        </Tooltip>
      </CardActions>

      <CardContent>{content}</CardContent>

      {mapsState.selectedMap !== undefined ? (
        <MapDetailsDialog onClose={() => dispatch(setSelectedMap(undefined))} map={mapsState.selectedMap} open={true} />
      ) : null}

      {exampleDatasetDialogOpen ? <UseExampleDatasetDialog onClose={() => setExampleDatasetDialogOpen(false)} /> : null}
      {createDialogOpen ? <CreateMapDialog onClose={() => setCreateDialogOpen(false)} /> : null}
    </Card>
  );
}
