import { useApolloClient } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent, FormGroup,
  FormLabel, MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useActions from '../../../app/hooks';
import { selectUser, UserState } from '../../../global/state/user-slice';
import BootstrapDialogTitle from '../../common/dialogs/BootstrapDialogTitle';

interface Props {
  children?: React.ReactNode;
  onClose: () => void;
  // onCloseSave: (robotId: string, sessionId: string, environmentId: string) => void;
}

export default function CreateMapDialog(props: Props) {
  const { children, onClose, ...other } = props;
  const globalState = useApolloClient();
  const actions = useActions();

  const userState: UserState | undefined = useSelector(selectUser);

  const [label, setLabel] = useState<string>('New Map');
  const [labelError, setLabelError] = useState<string>('');

  const [description, setDescription] = useState<string>('');
  const [dimensionality, setDimensionality] = useState<'2d' | '3d'>('2d');
  // const [robotId, setRobotId] = useState<string>('');
  // const [sessionId, setSessionId] = useState<string>('');
  // const [environmentId, setEnvironmentId] = useState<string>('');

  function handleCreateMap() {
    if (!userState?.user) return;

    actions.addMap({
      user: userState?.user,
      apolloClient: globalState,
      label: label,
      description: description,
      data: {
        dimensionality: dimensionality,
      },
    });
    props.onClose();
  }

  function onLabelChanged(e) {
    const newVal = e.target.value;
    setLabel(newVal);
    if (newVal.trim() == '') setLabelError("Map name can't be empty");
    else if (newVal.length > 64) setLabelError('Map name must be shorter than 64 characters');
    else {
      setLabelError('');
    }
  }

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth>
      <BootstrapDialogTitle id="dialog" onClose={props.onClose}>
        Create a New Map
      </BootstrapDialogTitle>
      <DialogContent>
        <FormGroup row={true} style={{ alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
          <FormLabel style={{ padding: '10px' }}>
            <b>Name</b>
          </FormLabel>
          <TextField
            variant="outlined"
            value={label}
            onChange={onLabelChanged}
            error={labelError !== ''}
            helperText={labelError}
          />
        </FormGroup>

        <FormGroup row={true} style={{ alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
          <FormLabel style={{ padding: '10px' }}>
            <b>Description</b>
          </FormLabel>
          <TextField
            variant="outlined"
            rows={4}
            multiline={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Provide an optional description of the map"
          />
        </FormGroup>

        <FormGroup row={true} style={{ alignItems: 'center', justifyContent: 'center' }}>
          <FormLabel style={{ padding: '10px' }}>
            <b>2D/3D</b>
          </FormLabel>
          <Select variant="outlined" value={dimensionality} onChange={(e) => setDimensionality(e.target.value)}>
            <MenuItem value={'2d'}>2D</MenuItem>
            <MenuItem value={'3d'}>3D</MenuItem>
          </Select>
        </FormGroup>

        <Accordion style={{ margin: '10px' }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Additional Settings...</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ justifyContent: 'center' }}>
            <Box>
              <FormGroup row={true} style={{ alignItems: 'center', justifyContent: 'center' }}>
                <FormLabel style={{ padding: '10px' }}>
                  <b>User</b>
                </FormLabel>
                <TextField disabled value={userState.user?.label} />
              </FormGroup>
              <FormGroup row={true} style={{ justifyContent: 'center' }}>
                <FormLabel style={{ padding: '10px' }}>
                  <b>Robot</b>
                </FormLabel>
                <TextField />
              </FormGroup>
              <FormGroup row={true} style={{ justifyContent: 'center' }}>
                <FormLabel style={{ padding: '10px' }}>
                  <b>Session</b>
                </FormLabel>
                <TextField />
              </FormGroup>
              <FormGroup row={true} style={{ justifyContent: 'center' }}>
                <FormLabel style={{ padding: '10px' }}>
                  <b>Environment</b>
                </FormLabel>
                <TextField />
              </FormGroup>
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={() => handleCreateMap()}
          disabled={labelError !== ""}>
          Create map
        </Button>
      </DialogActions>
    </Dialog>
  );
}
