import {
  Button,
  Card,
  CardActions, CardContent, CardHeader,
  CardMedia
} from '@mui/material';
import React from 'react';
import { DarkNegativeCreateIcon } from '../../icons/icons';


export default function HarborMapperDatasetCard() {
  return (
    <Card style={{ width: '100%' }}>
      <CardHeader title={`Harbor Example Dataset`} />
      <CardMedia>
          <img src="https://www.navability.io/wp-content/uploads/2022/01/Conceptual.png"></img>
      </CardMedia>
      <CardContent>
        <p>This is a marine dataset example showing:</p>
        <ul>
          <li>A</li>
        </ul>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="secondary"
          component="span"
          startIcon={<DarkNegativeCreateIcon fontSize="large" />}
        >
          Import this Dataset
        </Button>
      </CardActions>
    </Card>
  );
}
