import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { Map } from '../../../types/mapper/maps';
import BootstrapDialogTitle from '../../common/dialogs/BootstrapDialogTitle';
import MapVisualizerCard from './MapVisualizerBox';

/**
 * Placeholder container for showing a map.
 *
 * @export
 * @param {{map: Map}} props
 * @return {*}
 */
export default function MapVisualizerDialog(props: { map: Map; onClose: () => void }) {
  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="xl">
      <BootstrapDialogTitle id="dialog" onClose={props.onClose}></BootstrapDialogTitle>
      <DialogContent style={{ height: '95vh' }}>
        <MapVisualizerCard map={props.map} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={() => props.onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
