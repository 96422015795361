import { useApolloClient } from '@apollo/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser, UserState } from '../../../global/state/user-slice';
// import BootstrapDialogTitle from '../../common/dialogs/BootstrapDialogTitle';
// import '../../../../public/libs/potree/potree.css';
import { Box } from '@mui/material';
import PotreeControl from './PotreeControl';

const testMap = {
  id: 'b87e157e-4821-4e51-9315-e95ceaa8e7b5',
  label: 'TestMap',
  visualization: {
    hierarchyId: '712c4420-f54c-469e-872f-56c84f858fac',
    metadataId: '57257269-fdcc-4f19-8d16-c69d3386d5ff',
    octreeId: '96763826-570c-4b7b-a84e-8515e51c7ed3',
  },
  affordances: [
    {
      id: 'a1fa6a92-80e8-4244-a5c7-c3d18d5eb3e8',
      label: 'TestAffordance',
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [1, 1, 1],
    },
  ],
  annotations: [
    {
      id: '591d1442-7cde-4142-bc7f-39889a9c5e32',
      text: 'Show me somewhere',
      position: [0, 0, 0],
    },
  ],
  sessions: [
    {
      //TODO - make this a session from guest/marine.
      label: 'TestSession',
      robotLabel: 'TestRobot',
      userLabel: 'dan@testing.com',
    },
  ],
};

export default function MapVisualizerBox(props: { heightPx?: string; }) {
  const navAbilityClient = useApolloClient();
  // const actions = useActions();

  // const containerEl = useRef(null);

  const userState: UserState | undefined = useSelector(selectUser);

  // function componentDidMount() {

  // }

  // useEffect(() => {
  //   componentDidMount();
  // }, []);

  return (
    <Box>
      <PotreeControl navabilityClient={navAbilityClient} map={testMap} />
    </Box>
  );
}
