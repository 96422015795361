import { useApolloClient } from '@apollo/client';
import React from 'react';
import { useSelector } from 'react-redux';
import useActions from '../../app/hooks';
import SEO from '../../components/common/seo';
import { selectUser, UserState } from '../../global/state/user-slice';
import SubscriptionUpdates from '../activity/subscription-updates';
import Paywall from '../paywall/paywall';
import MapperSummaryCard from './maps/MapperSummaryCard';
import InProgressWorkflowsCard from './workflows/InProgressWorkflowsCard';

export default function MapperView() {
  const actions = useActions();
  const globalState = useApolloClient();

  const userState: UserState = useSelector(selectUser);

  // If the ID token changes, get the user.
//   const idToken = getIDTokenDecoded();
//   React.useEffect(() => {
//     // On mounting get the latest user
//     actions.getSelf({ userToken: idToken, apolloClient: globalState });  
//   }, [getIDToken()]);

//   console.log(userState);
  
  return (
    <>
      <SEO title="NavAbility Mapper" />
      <Paywall>
        <MapperSummaryCard />

        <InProgressWorkflowsCard />

        <SubscriptionUpdates />
      </Paywall>
    </>
  );
}
