import { useApolloClient } from '@apollo/client';
import { Refresh, Warning } from '@mui/icons-material';
import {
  Box,
  Button,
  Card, CardContent,
  CardHeader, CircularProgress,
  FormControl, IconButton,
  InputLabel,
  MenuItem,
  Select, Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useActions, { useAppDispatch } from '../../../../app/hooks';
import { selectUser, UserState } from '../../../../global/state/user-slice';
import { Robot } from '../../../../types/base';
import { Map } from '../../../../types/mapper/maps';
import { Workflow } from '../../../../types/mapper/workflows';
import AddUpdateRobotDialog from '../../../common/dialogs/AddUpdateRobotDialog';
import { DarkNegativeCreateIcon } from '../../../icons/icons';
import { RobotsState, selectRobots } from '../../../robots/RobotsSlice';

export default function SelectRobotCard(props: {
  map: Map;
  newWorkflow: Workflow;
  updateWorkflowCallback: (value: Workflow, isToMoveToNextScreen: boolean) => void;
}) {
  const globalState = useApolloClient();
  const dispatch = useAppDispatch();
  const actions = useActions();

  const robotsState: RobotsState | undefined = useSelector(selectRobots);
  const userState: UserState | undefined = useSelector(selectUser);

  const [isCreateRobotDialogOpen, setIsCreateRobotDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userState.user) actions.getRobots({ apolloClient: globalState, user: userState.user });
  }, []);

  function handleRobotSelected(robotId: string) {
    const newData = {...props.newWorkflow, data: {...props.newWorkflow.data, robotId: robotId}};
    props.updateWorkflowCallback(newData, true);
  }

  return (
    <Box>
      <Card style={{ width: '100%' }}>
        <CardHeader title="Select the robot that will store the session data"></CardHeader>
        <CardContent>
          <Box style={{ flex: 'display' }}>
            <FormControl>
              <InputLabel>Select a robot</InputLabel>
              <Select
                value={props.newWorkflow.data.robotId}
                label="Selected robot"
                onChange={(e) => handleRobotSelected(e.target.value)}
                style={{ minWidth: '250px' }}
                startAdornment={
                  robotsState.status == 'loading' ? (
                    <CircularProgress />
                  ) : robotsState.status == 'error' ? (
                    <Tooltip title={robotsState.error}><Warning color="error" /></Tooltip>
                  ) : null
                }
              >
                {robotsState.robots?.map((r) => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {robotsState.status == 'loading' ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={() =>
                  userState.user ? actions.getRobots({ apolloClient: globalState, user: userState.user }) : null
                }
                size="large">
                <Refresh />
              </IconButton>
            )}

            <Button
              variant="contained"
              color="secondary"
              component="span"
              startIcon={<DarkNegativeCreateIcon />}
              onClick={() => setIsCreateRobotDialogOpen(true)}
              style={{ marginLeft: '20px' }}
            >
              Add New Robot
            </Button>
          </Box>
        </CardContent>
        {isCreateRobotDialogOpen ? (
          <AddUpdateRobotDialog
            isUpdate={false}
            onClose={(robot: Robot | null) => {
              if (robot != null) {
                dispatch(actions.getRobots({ apolloClient: globalState, user: userState.user! }));

                props.newWorkflow.data.robotId = robot.id;
                props.updateWorkflowCallback(props.newWorkflow);
              }
              setIsCreateRobotDialogOpen(false);
            }}
          />
        ) : null}
      </Card>
    </Box>
  );
}
