import { AccountTree, ExpandMore, Redo } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Card, CardContent,
  CardHeader,
  Checkbox, FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';
import { Map } from '../../../../types/mapper/maps';
import { Workflow } from '../../../../types/mapper/workflows';

export default function TopicSelectionCard(props: {
  map: Map;
  newWorkflow: Workflow;
  updateWorkflowCallback: (value: Workflow) => void;
}) {
  return (
    <Card style={{ width: '100%' }}>
      <CardHeader title="Select which data to use"></CardHeader>
      <CardContent>
        <Accordion expanded={true}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>ROS data topics</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
            <TextField
              fullWidth
              label="Specify the ROS bag LIDAR topic"
              defaultValue="/example/lidar"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountTree />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <TextField
              fullWidth
              label="Specify the ROS bag IMU topic"
              defaultValue="/example/imu"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountTree />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <TextField
              fullWidth
              label="Specify ROS bag Camera topic (optional)"
              defaultValue="/example/camera"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountTree />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Tooltip title="If checked, then all other data will be uploaded to the variables.">
              <FormControlLabel control={<Checkbox />} label="Upload other data to variables" />
            </Tooltip>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={true}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Data decimation</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
            <Tooltip title="This is how many ROS samples are skipped between poses (variables).">
              <TextField
                fullWidth
                label="Data decimation (how many ROS frames to use per pose)"
                defaultValue="10"
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Redo />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Tooltip>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
}
