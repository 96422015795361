import { useApolloClient } from '@apollo/client';
import {
  Box
} from '@mui/material';
import { File } from 'navabilitysdk';
import React from 'react';
import { useSelector } from 'react-redux';
import useActions, { useAppDispatch } from '../../../../app/hooks';
import { selectUser, UserState } from '../../../../global/state/user-slice';
import { Map } from '../../../../types/mapper/maps';
import { Workflow } from '../../../../types/mapper/workflows';
import DataBlobsBox from '../../../data-dashboard/DataBlobsBox';

export default function SelectDataCard(props: {
  map: Map;
  newWorkflow: Workflow;
  updateWorkflowCallback: (value: Workflow, isToMoveToNextScreen: boolean) => void;
}) {
  const globalState = useApolloClient();
  const dispatch = useAppDispatch();
  const actions = useActions();

  const userState: UserState | undefined = useSelector(selectUser);

  function handleBlobSelected(blob: File) {
    const newData = {...props.newWorkflow, data: {...props.newWorkflow.data, blobId: blob.id}};
    props.updateWorkflowCallback(newData, true);
  }

  return (
    <Box>
      <DataBlobsBox onFileClicked={(file) => handleBlobSelected(file)}/>
    </Box>
  );
}
