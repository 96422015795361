import { useApolloClient } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, Step, StepButton, Stepper } from '@mui/material';
import { File } from 'navabilitysdk';
import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useActions from '../../../../app/hooks';
import { selectUser, UserState } from '../../../../global/state/user-slice';
import { Map } from '../../../../types/mapper/maps';
import { Workflow } from '../../../../types/mapper/workflows';
import BootstrapDialogTitle from '../../../common/dialogs/BootstrapDialogTitle';
import SelectDataCard from './SelectDataCard';
import SelectRobotCard from './SelectRobotCard';
import TopicSelectionCard from './TopicSelectionCard';


/**
 * Dialog for the AddData Workflow.
 *
 * @export
 * @param {{ map: Map; open: boolean; onClose: any }} props
 * @return {*} 
 */
export default function AddDataDialog(props: { map: Map; open: boolean; onClose: any }) {
  const apolloClient = useApolloClient();
  const actions = useActions();

  const userState: UserState | undefined = useSelector(selectUser);

  const [activeStep, setActiveStep] = React.useState(0);

  const [newWorkflow, setNewWorkflow] = React.useState<Workflow>({
    id: undefined,
    _type: "AddDataWorkflow",
    label: 'AddData_' + uuidv4().replace("-", "_"),
    description: `Adding data to map ${props.map.label} at ${new Date().toLocaleString()}`,
    status: 'draft',
    createdTimestamp: undefined,
    lastUpdatedTimestamp: undefined,
    createdBy: userState.user!,
    map: props.map,
    data: {
      userId: userState.user!.id,
      robotId: null,
      sessionId: 'AddData_' + uuidv4().replace("-", "_"),
      blobId: null,
      topics: {
        lidarTopic: '',
        imuTopic: '',
        cameraTopic: '',
        isOtherTopicsAdded: false,
      },
    },
    result: undefined
  });

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    if (activeStep != 2) {
      // Next step
      setActiveStep(activeStep + 1);
    } else {
      // Create the workflow
      actions.addWorkflow({
        apolloClient: apolloClient,
        user: userState!.user!,
        map: props.map,
        workflow: newWorkflow
      })
      props.onClose();
    }
  }
  

  /**
   * Select it, proceed to next step.
   *
   * @param {File} file
   */
  function onWorkflowUpdated(newWorkflow: Workflow, isToMoveToNextScreen: boolean = false) {
    setNewWorkflow(newWorkflow);
    if (isToMoveToNextScreen)
      handleNext();
  }

  var currentContent = null;
  switch (activeStep) {
    case 0:
      currentContent = (
        <SelectDataCard map={props.map} newWorkflow={newWorkflow} updateWorkflowCallback={onWorkflowUpdated} />
      );
      break;
    case 1:
      currentContent = (
        <SelectRobotCard map={props.map} newWorkflow={newWorkflow} updateWorkflowCallback={onWorkflowUpdated} />
      );
      break;
    case 2:
      currentContent = (
        <TopicSelectionCard map={props.map} newWorkflow={newWorkflow} updateWorkflowCallback={onWorkflowUpdated} />
      );
      break;
  }

  return (
    <Dialog fullWidth={true} maxWidth="md" open={props.open} onClose={props.onClose}>
      <BootstrapDialogTitle id="test" onClose={props.onClose}>
        Add data to: {props.map.label}
      </BootstrapDialogTitle>
      <DialogContent dividers style={{ padding: '40px' }}>
        <Stepper alternativeLabel nonLinear activeStep={activeStep} style={{marginBottom:"60px"}}>
          <Step key="upload">
            <StepButton color="inherit" onClick={() => setActiveStep(0)}>
              Select data
            </StepButton>
          </Step>
          <Step key="upload">
            <StepButton color="inherit" onClick={() => setActiveStep(1)}>
              Select robot
            </StepButton>
          </Step>
          <Step key="config">
            <StepButton color="inherit" onClick={() => setActiveStep(2)}>
              Data selection
            </StepButton>
          </Step>
        </Stepper>
        {currentContent}
      </DialogContent>
      <DialogActions style={{ padding: '20px' }}>
        <Button variant="outlined" onClick={() => props.onClose()}>
          Cancel
        </Button>
        {activeStep != 0 ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleBack();
            }}
          >
            Back
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            handleNext();
          }}
        >
          {activeStep != 2 ? 'Next' : 'Finish'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
