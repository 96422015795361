import { KeyboardArrowRight } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { Workflow } from '../../../types/mapper/workflows';
import { DocumentIcon } from '../../icons/icons';

export default function WorkflowSummaryTable(props: { workflows: Workflow[] }) {
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center">
              <Tooltip title="Name">
                <Typography style={{ fontWeight: 600 }}>Name</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip title="Status">
                <Typography>Status</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip title="Last active">
                <Typography>Last active</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip title="Description">
                <Typography>Description</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip title="Actions">
                <Typography>Actions</Typography>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.workflows.length == 0 ? (
            <TableRow key="0">
              <TableCell align="center" colSpan={6}>
                <Typography>It looks like you don't have any active workflows at the moment ¯\_(ツ)_/¯</Typography>
              </TableCell>
            </TableRow>
          ) : null}
          {props.workflows.map((workflow, i) => (
            <TableRow key={i.toString()}>
              <TableCell align="center">
                <DocumentIcon fontSize="large" />
              </TableCell>
              <TableCell align="center">
                <Tooltip title={workflow.label}>
                  <Button style={{ fontWeight: 600 }}>{workflow.label}</Button>
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                <Typography>{workflow.status}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{new Date(workflow.lastUpdatedTimestamp!).toLocaleString()}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{workflow.description}</Typography>
              </TableCell>
              <TableCell align="center">
                <IconButton edge="end" size="large">
                  <KeyboardArrowRight fontSize="large" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
