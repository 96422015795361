import { useApolloClient } from '@apollo/client';
import { Delete, Edit, Lock } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip
} from '@mui/material';
import { getDownloadUrl } from 'navabilitysdk';
import React, { useState } from 'react';
import { Map } from '../../../types/mapper/maps';
import BootstrapDialogTitle from '../../common/dialogs/BootstrapDialogTitle';
import { SendIcon } from '../../icons/icons';
import { DarkNegativeCreateIcon } from '../../icons/svg/CreateIcon';
import MapVisualizerDialog from '../visualization/MapVisualizerDialog';
import AddDataDialog from '../workflows/add-data/AddDataDialog';
import WorkflowSummaryTable from '../workflows/WorkflowSummaryTable';

export default function MapDetailsDialog(props: { map: Map; open: boolean; onClose: any }) {
  const globalState = useApolloClient();

  const [addDataDialogOpen, setAddDataDialogOpen] = useState<boolean>(false);
  const [visualizeOpen, setVisualizeOpen] = useState<boolean>(false);

  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(undefined);

  React.useEffect(async () => {
    console.log(props.map.thumbnailId);
    // On mounting get the latest user
    if (!props.map.thumbnailId)
      setThumbnailUrl('https://image.freepik.com/free-photo/river-foggy-mountains-landscape_1204-511.jpg');
    else {
      const thumbnail = await getDownloadUrl(globalState, props.map.thumbnailId);
      setThumbnailUrl(thumbnail);
    }
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="xl" open={props.open} onClose={props.onClose}>
      <BootstrapDialogTitle id="test" onClose={props.onClose}>
        {props.map.label}{' '}
        {props.map.status == 'draft' ? <Chip label="Draft" /> : <Chip color="secondary" label="Published" />}
      </BootstrapDialogTitle>
      <DialogContent dividers style={{ padding: '40px' }}>
        <Grid container>
          {/* Info summary */}
          <Grid item xs={6}>
            <Card>
              <CardHeader
                title="Map Details"
                action={
                  <Box style={{ display: 'flex' }}>
                    <IconButton color="primary" size="large">
                      <Edit></Edit>
                    </IconButton>
                  </Box>
                }
              ></CardHeader>
              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">{props.map.label}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell style={{ fontWeight: 300 }}>{props.map.description}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Team Members</TableCell>
                      <TableCell align="center" style={{ fontWeight: 300 }}>
                        {/* <AvatarGroup> */}
                        {props.map.userRoles.map((userRole) => (
                          <Tooltip key={userRole.user.label + '_' + userRole.user.role} title={userRole.user.label}
                          sx={{m: 0.5}}>
                            <Badge badgeContent={userRole.role == 'admin' ? <Lock color="secondary" /> : ''}>
                              <Avatar>{userRole.user.label.toUpperCase()[0]}</Avatar>
                            </Badge>
                          </Tooltip>
                        ))}
                        {/* </AvatarGroup> */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell align="center" style={{ fontWeight: 300 }}>
                        {props.map.status == 'draft' ? (
                          <Chip label="Draft" />
                        ) : (
                          <Chip color="secondary" label="Published" />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="center" style={{ fontWeight: 300 }}>
                        {props.map.id}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Created</TableCell>
                      <TableCell align="center" style={{ fontWeight: 300 }}>
                        {new Date(props.map.createdTimestamp).toLocaleString()}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Last updated</TableCell>
                      <TableCell align="center" style={{ fontWeight: 300 }}>
                        {new Date(props.map.lastUpdatedTimestamp).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>

          {/* Visualization */}
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {/* Action area */}
            <CardActions style={{ marginBottom: '30px' }}>
              <Button
                variant="contained"
                color="secondary"
                component="span"
                size="large"
                startIcon={<DarkNegativeCreateIcon fontSize="large" />}
                onClick={() => setAddDataDialogOpen(true)}
              >
                Add Data
              </Button>
              <Button
                variant="contained"
                color="secondary"
                component="span"
                size="large"
                startIcon={<SendIcon fontSize="large" />}
                // onClick={() => setCreateDialogOpen(true)}
              >
                Export Map
              </Button>
              <Divider orientation="vertical" style={{ margin: '10px' }}></Divider>
              <Button
                variant="contained"
                component="span"
                size="large"
                startIcon={<Delete fontSize="large" />}
                // onClick={() => setCreateDialogOpen(true)}
              >
                Delete Map
              </Button>
            </CardActions>
            <Card>
              <CardActionArea>
                {!thumbnailUrl ? (
                  <CircularProgress />
                ) : (
                  <CardMedia component="img" sx={{ maxHeight: '400px' }} image={thumbnailUrl}></CardMedia>
                )}
                <CardActions>
                  <Button variant="contained" color="secondary" onClick={() => setVisualizeOpen(true)}>
                    Visualize Map
                  </Button>
                  <Button variant="contained" color="secondary">
                    Export Map...
                  </Button>
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        <Card sx={{ marginTop: 2 }}>
          <CardHeader
            title="Active Workflows"
            subheader="These are the workflows in-progress for this map"
          ></CardHeader>
          <CardContent>
            <WorkflowSummaryTable workflows={props.map.workflows} />
          </CardContent>
        </Card>
      </DialogContent>

      {/* The AddDataDialog */}
      {addDataDialogOpen ? (
        <AddDataDialog
          map={props.map}
          open={addDataDialogOpen}
          onClose={() => setAddDataDialogOpen(false)}
        ></AddDataDialog>
      ) : null}

      {visualizeOpen ? <MapVisualizerDialog map={props.map} onClose={() => setVisualizeOpen(false)} /> : null}
    </Dialog>
  );
}
