import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grow, IconButton, MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import HarborMapperDatasetCard from './example-datasets/harbor-mapper-dataset-card';
import WarehouseMapperDatasetCard from './example-datasets/warehouse-mapper-dataset';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const exampleData = [
  {
    id: 0,
    card: <HarborMapperDatasetCard />,
    name: 'Harbor Mapping Dataset',
  },
  {
    id: 1,
    card: <WarehouseMapperDatasetCard />,
    name: 'Warehouse Mapping Dataset',
  },
];

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle style={{ margin: 0, padding: '20px' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function UseExampleDatasetDialog(props: { onClose: any }) {
  const [selectedId, setSelectedId] = useState<number>(0);

  return (
    <Dialog fullWidth={true} maxWidth="xl" open={true} onClose={props.onClose}>
      <BootstrapDialogTitle id="test" onClose={props.onClose}>
        Import Example Dataset
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box display="flex" justifyContent="center" alignContent="center">
          <Typography style={{margin:"10px"}}>Available Datsets:</Typography>
          <Select label="Example Projects" value={selectedId} onChange={(e) => setSelectedId(e.target.value as number)}>
            {exampleData.map((example) => (
              <MenuItem key={example.id} value={example.id}>
                {example.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {selectedId >= 0 ? <Grow in={true}>{exampleData[selectedId].card}</Grow> : <Typography>Please select an example dataset.</Typography>}
        {/* <ProjectSummaryCard project={props.project} /> */}
      </DialogContent>
    </Dialog>
  );
}
