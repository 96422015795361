import { useApolloClient } from '@apollo/client';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import useActions, { useAppDispatch } from '../../../app/hooks';
import { selectUser, UserState } from '../../../global/state/user-slice';
import { selectWorkflows, WorkflowState } from '../../../global/state/workflow-slice';
import { Spinner } from '../../common/spinner';
import WorkflowSummaryTable from './WorkflowSummaryTable';

export default function InProgressWorkflowsCard() {
  const globalState = useApolloClient();
  const dispatch = useAppDispatch();
  const actions = useActions();

  const workflowState: WorkflowState | undefined = useSelector(selectWorkflows);
  const userState: UserState | undefined = useSelector(selectUser);

  // If the ID token changes, get the maps.
  // const idToken = getIDTokenDecoded();
  React.useEffect(() => {
    // On mounting get the latest user
    if (userState.user != undefined) actions.getWorkflows({ apolloClient: globalState, user: userState.user });
  }, [userState.user]);

  var content = null;
  if (workflowState.status == 'error') {
    content = (
      <Alert
        severity="error"
        action={
          <Button
            onClick={() =>
              userState.user != undefined
                ? actions.getWorkflows({ apolloClient: globalState, user: userState.user })
                : null
            }
          >
            Reload
          </Button>
        }
      >
        {workflowState.error}
      </Alert>
    );
  }
  if (workflowState.status == 'loading') {
    content = <Spinner />;
  }
  if (workflowState.status == 'idle') {
    content = <WorkflowSummaryTable workflows={workflowState.workflows} />;
  }

  return (
    <Card style={{ marginBottom: '10px', padding: '10px' }}>
      <CardHeader title="Workflows" subheader="In-progress workflows against maps that need your attention." />
      <CardContent style={{ marginTop: '20px' }}>{content}</CardContent>
    </Card>
  );
}
