import { useApolloClient } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, Typography
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import useActions from '../../../app/hooks';
import { selectUser, UserState } from '../../../global/state/user-slice';
import { Map } from '../../../types/base';
import BootstrapDialogTitle from '../../common/dialogs/BootstrapDialogTitle';

interface Props {
  children?: React.ReactNode;
  onClose: () => void;
  map: Map;
}

export default function DeleteMapDialog(props: Props) {
  const { children, onClose, ...other } = props;
  const globalState = useApolloClient();
  const actions = useActions();

  const userState: UserState | undefined = useSelector(selectUser);


  function handleDeleteClicked() {
    if (!userState?.user) return;

    actions.deleteMap({
      user: userState?.user,
      apolloClient: globalState,
      mapId: props.map.id,
    });
    props.onClose();
  }

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth>
      <BootstrapDialogTitle id="dialog" onClose={props.onClose}>
        Are you sure?
      </BootstrapDialogTitle>
      <DialogContent>
        <Typography>This is a permanent action, are you sure?</Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="outlined" 
          color="secondary" 
          onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={() => handleDeleteClicked()}>
          Delete it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
