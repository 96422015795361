import { useApolloClient } from '@apollo/client';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { CardActionArea, CardActions, CardHeader, Chip, Grid, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { getDownloadUrl } from 'navabilitysdk';
import React, { useState } from 'react';
import useActions, { useAppDispatch } from '../../../app/hooks';
import { setSelectedMap } from '../../../global/state/maps-slice';
import { Map } from '../../../types/mapper/maps';
import DeleteMapDialog from './DeleteMapDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'initial',
  },
  title: {
    marginBottom: '30px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  card: {
    width: 300,
  },
  media: {
    paddingTop: '56.25%',
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
    '&:not(:first-of-type)': {
      marginLeft: 1,
    },
  },
}));

export default function MapSummaryItem(props: { map: Map }) {
  const globalState = useApolloClient();
  const dispatch = useAppDispatch();
  const actions = useActions();

  const classes = useStyles();

  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(undefined);

  React.useEffect(async () => {
    console.log(props.map.thumbnailId);
    // On mounting get the latest user
    if (!props.map.thumbnailId)
      setThumbnailUrl('https://image.freepik.com/free-photo/river-foggy-mountains-landscape_1204-511.jpg');
    else {
      const thumbnail = await getDownloadUrl(globalState, props.map.thumbnailId);
      console.log(thumbnail);
      setThumbnailUrl(thumbnail);
    }
  }, []);

  const [deletionDialogOpen, setDeletionDialogOpen] = useState<Boolean>(false);

  function onCardClick(e) {
    dispatch(setSelectedMap(props.map));
    e.preventDefault();
  }

  return (
    <Grid item>
      <Card
        key={props.map.id}
        className={classes.card}
        onClick={onCardClick}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <CardActionArea>
          <CardMedia className={classes.media} image={thumbnailUrl} />
          <CardHeader
            title={props.map.label}
            subheader={
              props.map.status == 'draft' ? <Chip label="Draft" /> : <Chip color="secondary" label="Published" />
            }
          ></CardHeader>
        </CardActionArea>
        <CardContent>
          {props.map.description}
          <Divider light />
        </CardContent>
        <CardActionArea sx={{ alignSelf: 'flex-end' }}>
          <CardActions>
            <IconButton color="primary" size="large">
              <Edit></Edit>
            </IconButton>
            <IconButton size="large">
              <Visibility></Visibility>
            </IconButton>
            <IconButton
              edge="end"
              onClick={(e) => {
                setDeletionDialogOpen(true);
                e.stopPropagation();
              }}
              size="large"
            >
              <Delete></Delete>
            </IconButton>
          </CardActions>
        </CardActionArea>
      </Card>

      {deletionDialogOpen ? <DeleteMapDialog map={props.map} onClose={() => setDeletionDialogOpen(false)} /> : null}
    </Grid>
  );
}
